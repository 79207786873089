// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-2-js": () => import("./../../../src/pages/index2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

